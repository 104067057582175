export const DEFAULT_SEGMENT_LIMIT_BACKGROUND_FETCH = 4;

export const DEFAULT_SEGMENT_LIMIT_BACKGROUND_FETCH_MAP = {
  250: 2,
  500: 2,
  1000: 4,
  2000: 4,
  4000: 6,
  8000: 10,
};
